<script>
import {mapMutations} from "vuex";
import UserDashboardLayout from "../../layouts/UserDashboardLayout.vue";

export default {
    name: "Notifications",
    components: {UserDashboardLayout},
    data() {
        return {
            notifications: [],
        };
    },
    mounted() {
        this.fetchNotifications();
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        fetchNotifications() {
            window.axios.get("/api/notifications").then((response) => {
                this.notifications = response.data;
            });
        },
        setEnabled() {
            let enabledNotifications = this.notifications
                .filter(notification => notification.is_enabled)
                .map(notification => notification.notification);
            window.axios.post("/api/notifications/set-enabled", {
                enabled: enabledNotifications,
            }).then(() => {
                this.showSnackBar({color: 'success', timeout: 3000, text: "saved"})
            });
        }
    }
};
</script>

<template>
    <user-dashboard-layout>
        <v-simple-table>
            <thead>
            <tr>
                <th>{{$t('general.name')}}</th>
                <th>{{$t('general.enabled')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="notification in notifications" :key="notification.id">
                <td>{{ notification.notification }}</td>
                <td>
                    <v-checkbox v-model="notification.is_enabled"/>
                </td>
            </tr>
            </tbody>
        </v-simple-table>
        <div style="display: flex; align-items: center; justify-content: end;" class="py-4">
            <v-btn @click="setEnabled">Save</v-btn>
        </div>
    </user-dashboard-layout>
</template>

<style scoped>

</style>
